import { Message, Alert } from 'rsuite'
import { convertFromRaw } from 'draft-js'
import moment from 'moment'
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper'
import { Router } from '../../server/routes'
// eslint-disable-next-line import/no-named-as-default
import api from './_http'
// eslint-disable-next-line import/no-cycle
import { getLive, getSync, setSession, setStorage, setSync } from '../utils/Storken'
// eslint-disable-next-line import/no-cycle
import { event } from './EventActions'
// eslint-disable-next-line import/no-cycle
import { pixelEvent } from './pixel'

export const onChanger = (key, value) => {
  const nextData = Object.assign({}, getSync('order'))
  nextData[key] = value
  setSync('order', nextData)
}
export const addBasket = (e) => {
  const basketOBJ = { ...e }
  basketOBJ.quantity = 1
  basketOBJ.cost = e.packsize.price
  setSync('basket', [...getSync('basket'), basketOBJ])
  setStorage('baskets', [])
  setStorage('baskets', getSync('basket'))
  setCost()
  event('add_to_cart', [basketOBJ], basketOBJ?.packsize?.price)
  pixelEvent('AddToCart', basketOBJ)
}
export const setCost = () => {
  setSync('cost', 0)
  let total = 0
  const items = getSync('basket')
  // eslint-disable-next-line no-return-assign
  if (items?.length > 0) {
    items.map(({ quantity, packsize }) => total += quantity * packsize.price)
  }
  setSync('cost', total)
}
export const removeToInBasket = (id, size) => {
  const indexOfBasket = getSync('basket').findIndex(({ _id, packsize }) => _id === id && packsize.size === size)
  const items = [...getSync('basket')]
  event('remove_from_cart', [items[indexOfBasket]], items[indexOfBasket]?.cost)
  items.splice(indexOfBasket, 1)
  setSync('basket', [])
  setSync('basket', items)
  setStorage('baskets', [])
  setStorage('baskets', items)
  setCost()
}

export const setQuantity = (girdi, prodID, size) => {
  const items = getSync('basket')
  const index = items.findIndex(p => p._id === prodID && p?.packsize.size === size)
  const prodOnEdit = { ...items[index] }
  prodOnEdit.quantity += girdi
  if (prodOnEdit?.stocked && prodOnEdit?.quantity > prodOnEdit?.packsize?.stock) {
    Alert.error(' Bu paket boyutundaki son ürünü eklediniz', 5000)
  } else if (prodOnEdit.quantity === 0) {
    removeToInBasket(prodID, size)
  } else {
    prodOnEdit.cost = prodOnEdit?.quantity * prodOnEdit?.packsize?.price
    items.splice(index, 1, prodOnEdit)
    setSync('basket', [])
    setSync('basket', items)
    setStorage('baskets', [])
    setStorage('baskets', items)
    if (girdi > 0) {
      pixelEvent('AddToCart', prodOnEdit)
      event('add_to_cart', [prodOnEdit], prodOnEdit?.packsize?.price)
    } else {
      event('remove_from_cart', [prodOnEdit], prodOnEdit?.packsize?.price)
    }
    setCost()
  }
}
export const createNewOrder = data => api.post('/neworder', data).then((resp) => {
  Router.pushRoute(`/siparis/admin/${resp.data.orderID}`)
})
export const updateOrder = data => api.post('/updateorder', data).then((resp) => {
  Router.pushRoute(`/siparis/duzenlendi/${resp.data.orderID}`)
  setSession('editMode', false)
  setStorage('customer', [])
})
export const createPluxeePayment = data => api.post('/createplux', data)
export const createPayment = data => api.post('/secure3d', data)
export const getorder = data => api.post('/getorder', data)
export const getorderswithphone = data => api.post('/getorderswithphone', data)
export const auth = () => api.get('/isAuthenticated').then(resp => resp?.data?.auth)
export const sendCode = data => api.post('/sendcode', data)
export const verifyCheck = data => api.post('/verifycheck', data)
export const getRateAvg = id => api.get(`/getreteavg/${id}`)
export const getOffer = data => api.post('/getoffer', data)
export const getAnalysisReports = id => api.get(`/analysisreports/${id}`)
export const tokenControl = token => api.get('/token-control', {}, {
  Authorization: `Bearer ${token}`
})
export const addAdressTo = (data, token) => api.post('/add-address', data, {
  Authorization: `Bearer ${token}`
})
export const removeAdressTo = (index, id, token) => api.get(`/remove-address/${index}/${id}`, {}, {
  Authorization: `Bearer ${token}`
})
export const createComment = (token, data) => api.post('/createcomm', data, {
  Authorization: `Bearer ${token}`
})
export const verifyCode = data => api.post('/verifycode', data)
export const findCode = data => api.post('/findcode', data)
export const findFatura = data => api.post('/findfatura', data)


export const getCargoCost = cost => (cost > 99 ? 100 : 100)
export const getRealCost = (isPack, discount, disCost, cost) => (isPack ? (discount
  ? disCost : cost) : (discount
  ? disCost + getCargoCost(cost) : cost + getCargoCost(cost)))

export const getDesc = (blockData) => {
  if (JSON.stringify(blockData)?.indexOf('blocks') > -1) {
    return convertFromRaw(JSON.parse(blockData)).getPlainText()
  } return blockData
}
export const controlBeforeSetStep = (changeStep) => {
  changeStep === 0
    ? Router.pushRoute('/yenisiparis#bilgilerim')
    : changeStep === 1
      ? Router.pushRoute('/yenisiparis#adresim')
      : !getLive('isAuth') && changeStep === 2
        ? Router.pushRoute('/yenisiparis#kart')
        : null
}

// eslint-disable-next-line prefer-spread
export const getMinPrice = data => Math?.min?.apply(Math, data?.map(({ price }) => price))
export const getSeoSizeName = data => data?.find(({ price }) => price === getMinPrice(data))?.size

export const slugify = (text) => {
  const trMap = {
    çÇ:'c',
    ğĞ:'g',
    şŞ:'s',
    üÜ:'u',
    ıİ:'i',
    öÖ:'o'
  }
  // eslint-disable-next-line guard-for-in,no-restricted-syntax,no-loops/no-loops
  for (const key in trMap) {
    // eslint-disable-next-line no-param-reassign
    text = text?.replace(new RegExp(`[${key}]`, 'g'), trMap[key])
  }
  return text?.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
    .replace(/\s/gi, '-') // convert spaces to dashes
    .replace(/[-]+/gi, '-') // trim repeated dashes
    .toLowerCase()
}

export const teslimatTarihiHesapla = () => {
  const suankiZaman = moment().locale('tr')

  const saatYediBucaq = suankiZaman.clone().hour(17).minute(0).second(0)
  const bugununGunAdi = suankiZaman.format('dddd')
  let kargoGunu

  if (bugununGunAdi === 'Pazar') {
    kargoGunu = suankiZaman.add(2, 'days')
  } if (bugununGunAdi === 'Pazartesi') {
    kargoGunu = suankiZaman.add(1, 'days')
  } if (bugununGunAdi === 'Salı') {
    if (suankiZaman.isBefore(saatYediBucaq)) {
      kargoGunu = suankiZaman
    } else {
      kargoGunu = suankiZaman.add(1, 'days')
    }
  } if (bugununGunAdi === 'Çarşamba') {
    if (suankiZaman.isBefore(saatYediBucaq)) {
      kargoGunu = suankiZaman
    } else {
      kargoGunu = suankiZaman.add(3, 'days')
    }
  }
  if (bugununGunAdi === 'Perşembe') {
    kargoGunu = suankiZaman.add(2, 'days')
  }
  if (bugununGunAdi === 'Cuma') {
    kargoGunu = suankiZaman.add(1, 'days')
  }
  if (bugununGunAdi === 'Cumartesi') {
    if (suankiZaman.isBefore(saatYediBucaq)) {
      kargoGunu = suankiZaman
    } else {
      kargoGunu = suankiZaman.add(3, 'days')
    }
  }
  const teslimatGunu = kargoGunu.add(2, 'days')
  return teslimatGunu.format('D MMMM')
}

export const getMinCargoCost = () => (1800)
export const CustomerActions = {
  addBasket,
  setQuantity,
  removeToInBasket
}
