import React from 'react'
import { Icon } from 'rsuite'
import { Router } from '../../../../server/routes'
import { setSync } from "../../../utils/Storken";

const links = [
  { title: 'Yönetim Paneli', href: '/panel', icon: 'dashboard', roles: ['public', 'user', 'admin'] },
  { title: 'Siparişler', href: '/siparisler', icon: 'shopping-basket', roles: ['user', 'admin'] },
  { title: 'Ürünler', href: '/urunler', icon: 'shopping-cart', roles: ['admin'] },
  { title: 'Yorumlar', href: '/yorumlar', icon: 'edit', roles: ['admin'] },
  { title: 'Kategoriler', href: '/kategoriler', icon: 'object-group', roles: ['admin'] },
  { title: 'Kuponlar', href: '/indirimler', icon: 'tag', roles: ['public', 'admin'] },
  { title: 'Teklifler', href: '/teklifler', icon: 'tag', roles: ['public', 'admin'] },
  { title: 'Kargo Yükle', href: '/yukleme', icon: 'tag', roles: ['user', 'admin'] },
  { title: 'Kargo Takibi', href: '/kargotakip', icon: 'tag', roles: ['user', 'admin'] },
  { title: 'Müşteriler', href: '/musteriler', icon: 'user-analysis', roles: ['admin'] }
];

const AdminMenu = ({ path, user }) => {
  const filteredLinks = links.filter(link => link.roles.includes(user.role));

  return (
    <>
      {filteredLinks.map(e => (
        <div key={e.href} className='eleman'>
          <a className={e.href === path ? 'aktif' : ''} onClick={() => {
            Router.pushRoute(e.href);
            setSync('onleft', false);
          }}>
            <span><Icon icon={e.icon} /></span>
            {e.title}
          </a>
        </div>
      ))}
    </>
  );
};

export default AdminMenu;
