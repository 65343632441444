import React, { useState, useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import { IconButton, Icon, Message, Panel, Placeholder, SelectPicker, Loader, Badge, Tag, Rate } from 'rsuite'
import { LazyImage } from 'react-lazy-images'
import { getSync, useLive, useSync } from '../../../utils/Storken'
import {
  addBasket,
  getMinCargoCost, getRateAvg,
  setQuantity,
  slugify,
  teslimatTarihiHesapla
} from '../../../services/CustomerActions'
import { Router } from '../../../../server/routes'
import { QuantityComponent } from './QuantityComponent'


const { Graph } = Placeholder
// eslint-disable-next-line react/prop-types
const Urunler = ({ prod, categories }) => {
  const [basket] = useSync('basket')
  const [inBasket, setInBakset] = useState(Boolean)
  const [miktar, Miktar] = useState(0)
  const [sizeprice, setSizePrice] = useState({})
  const [sizeData, setSizeData] = useState([])
  const [avg, setAvg] = useState(0)
  const [totalRate, setTotalRate] = useState(0)

  useEffect(() => {
    if (prod?._id) {
      getRateAvg(prod?._id)
        .then((resp) => {
          if (resp.status === 200) {
            const { averageRating, totalComments } = resp.data
            setAvg(averageRating)
            setTotalRate(totalComments)
          }
        })
    }
  }, [prod?._id])

  // eslint-disable-next-line no-shadow
  useEffect(() => {
    if (sizeprice === undefined || Object.keys(sizeprice).length === 0) {
      const oneOf = basket.find(({ _id }) => _id === prod?._id)
      if (oneOf) setSizePrice(oneOf.packsize)
      else if (prod?.sizeprice.length === 1) {
        setSizePrice(prod?.sizeprice[0])
      } else {
        const validPack = prod?.sizeprice?.find(({ stock }) => stock > 0)
        if (validPack === undefined) setSizePrice(prod?.sizeprice[0])
        else setSizePrice(validPack)
      }
    }

    const basketIndex = getSync('basket')?.findIndex(p => p._id === prod._id && p?.packsize?.size === sizeprice?.size)
    if (basketIndex > -1) {
      setInBakset(true)
      Miktar(basket[basketIndex]?.quantity)
    } else {
      setInBakset(false)
    }
  }, [basket, prod._id, prod.sizeprice, setInBakset, sizeprice])

  useEffect(() => {
    const sizePriceData = prod?.sizeprice?.map(e => ({ label:e?.size, value:{ size:e.size, price:e.price, _id:e._id, stock:e?.stock } }))
    setSizeData(sizePriceData)
  }, [prod.sizeprice])
  const addBasketControl = () => {
    const prodObj = { ...prod }
    prodObj.packsize = sizeprice
    addBasket(prodObj)
  }
  const openProduct = () => {
    Router.pushRoute('urun', { id:slugify(prod?.name) })
  }

  return (
    <>
      <Panel className='urun_card'>
        <Grid>
          <Grid.Column onClick={openProduct} width={16}>
            <LazyImage
              DebounceDurationMs={1000}
              className='urun_img'
              src={prod.photos[0].data_url}
              style={{ width: '100%' }}
              placeholder={({ imageProps, ref }) => (
                <div ref={ref}>
                  <Graph active />
                </div>
              )}
              actual={({ imageProps }) => <img {...imageProps} />}
            />
            {/*
              props.urun.indirimde ? <span className='indirim'>İndirimde</span> : null
              */
            }
          </Grid.Column>
          <Grid.Column width={16}>
            <div className='urun_card_bilgi'>
              <h3 onClick={openProduct} className='urun_ad'>{prod?.name}</h3>
              {
                prod?.sizeprice.length < 2
                  ? <span>{sizeprice?.size}</span>
                  : (
                    <SelectPicker
                      cleanable={false}
                      className='size_selector'
                      searchable={false}
                      defaultValue={{ label:sizeprice?.size, value:{ size:sizeprice?.size, price: sizeprice?.price, _id:sizeprice?._id, stock:sizeprice?.stock } }}
                      placeholder={inBasket ? basket[basket?.findIndex(p => p?._id === prod?._id)]?.packsize?.size : sizeprice?.size}
                      style={{ width: 224 }}
                      data={sizeData}
                      onSelect={e => setSizePrice(e)}
                    />
                  )
              }
            </div>
            {totalRate > 0 && (
              <><Rate allowHalf style={{ color:'#FFA41C' }} readOnly size='xs' value={avg} /><span style={{fontSize:'11px', marginLeft:'-2px'}}>({totalRate})</span></>
            )}
            <div className='urun_meta'>

              <div className='urun_meta_bilgi'>
                <span className='urun_ucret'>{inBasket ? basket[basket?.findIndex(p => p?._id === prod?._id && p.packsize.size === sizeprice.size)]?.packsize?.price : sizeprice?.price} TL</span>
              </div>
            </div>
            {sizeprice?.price > getMinCargoCost()
             && (
               <div className='sproduct-kargo'>
                 <Icon icon='check' />Kargo BEDAVA
               </div>
             )}
            <div className='sproduct-teslimat'>
              {teslimatTarihiHesapla()} tarihinde teslim
            </div>
            {(categories?.find(e => e?.name === 'Yağlar')?._id === prod?.category
            || categories?.find(e => e?.name === 'Peynirler')?._id === prod?.category
            || categories?.find(e => e?.name === 'Paketler')?._id === prod?.category
                || prod?.name === 'Arı Sütü')
              && (<div className='sproduct-ice'><span>Buz aküsü ile gönderilir</span></div>)
            }
            {
              prod?.stocked && sizeprice?.stock > 0 && sizeprice?.stock < 20 && (
                <span className='sproduct-stok'>Stokta sadece {sizeprice?.stock} adet kaldı</span>
              )
            }
            {
              prod.active
                ? (
                  <>
                    {
                      inBasket
                        ? (
                          <QuantityComponent
                            iconClass='urun_counter'
                            divClass='urun_counter_katman'
                            _id={prod._id}
                            size={sizeprice?.size}
                            count={miktar}
                          />
                        )
                        // eslint-disable-next-line max-len
                        : (prod?.stocked && sizeprice?.stock > 0) || !prod?.stocked ? (
                          <IconButton
                            className='urun_buton'
                            onClick={addBasketControl}
                            icon={<Icon icon='plus' />}
                            placement='right'
                          >Sepete Ekle
                          </IconButton>
                        )
                          : (
                            <IconButton
                              className='urun_buton'
                              icon={<Icon icon='clock-o' />}
                              placement='left'
                            >
                              Bu paket tükendi
                            </IconButton>
                          )
                    }
                  </>
                )
                : (
                  <Message
                    showIcon
                    type='warning'
                    description='Tükendi'
                    className='stok_uyarı'
                  />
                )
            }

          </Grid.Column>
        </Grid>
      </Panel>
    </>
  )
}
export default Urunler
