import { Icon, Dropdown, Button } from 'rsuite';
import { withRouter } from 'next/router';
import React from 'react';
import Link from 'next/link';
import { useSync, useLive, useStorage } from '../../../utils/Storken';
import { signOutWithUser } from '../../../services/UserActions';
import { Router } from '../../../../server/routes';

const menuItems = [
  { title: 'Yönetim Paneli', eventKey: '/panel', roles: ['user', 'admin', 'public'] },
  { title: 'Siparişler', eventKey: '/siparisler', roles: ['user', 'admin'] },
  { title: 'Kargo Yükle', eventKey: '/yukleme', roles: ['user', 'admin'] },
  { title: 'Kargo Takibi', eventKey: '/kargotakip', roles: ['user', 'admin'] },
  { title: 'Kuponlar', eventKey: '/indirimler', roles: ['admin', 'public'] },
  { title: 'Teklifler', eventKey: '/teklifler', roles: ['admin', 'public'] },
  { title: 'Yorumlar', eventKey: '/yorumlar', roles: ['admin'] },
  { title: 'Ürünler', eventKey: '/urunler', roles: ['admin'] },
  { title: 'Kategoriler', eventKey: '/kategoriler', roles: ['admin'] },
  { title: 'Kullanıcılar', eventKey: '/users', roles: ['admin'] }
];

const Header = ({ router }) => {
  const [tokenCustomer] = useStorage('tokenCustomer');
  const [onLeft, OnLeft] = useSync('onleft');
  const [user] = useLive('user');

  const openUrl = (url) => Router.pushRoute(url);

  const HeaderDrop = () => (
    <Dropdown activeKey={router?.asPath} onSelect={(e) => openUrl(e)} title={user.username}>
      {menuItems
        .filter((item) => item.roles.includes(user.role))
        .map((item) => (
          <Dropdown.Item key={item.eventKey} eventKey={item.eventKey}>
            {item.title}
          </Dropdown.Item>
        ))}
      <Dropdown.Item eventKey='/' onSelect={() => signOutWithUser()}>
        Çıkış Yap
      </Dropdown.Item>
    </Dropdown>
  );

  return (
    <header className='app-header'>
      <div className='sol_menu_buton item' onClick={() => OnLeft(!onLeft)}>
        <span />
        <span />
        <span />
      </div>
      <div className='nav-brand item'>
        <span>
          <Link href='/'>
            <img src='https://svgl.s3.eu-west-3.amazonaws.com/kg-logo.jpg' alt='' />
          </Link>
        </span>
      </div>
      <div className='right-menu item'>
        {tokenCustomer?.name ? (
          <a href='/profile'>{tokenCustomer.name.split(' ')[0]}</a>
        ) : (
          <a href='/customer-login'>Giriş yap</a>
        )}
        <Icon icon='user-o' />
      </div>
      <div className='desktop-header-list'>
        <Button className='login-customer-button'>
          {tokenCustomer?.name ? (
            <a href='/profile'>{tokenCustomer.name}</a>
          ) : (
            <a href='/customer-login'>Giriş yapın</a>
          )}
        </Button>
        <span>{user.username && <HeaderDrop />}</span>
      </div>
    </header>
  );
};

export default withRouter(Header);
